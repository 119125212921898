import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Modal } from 'react-bootstrap';
import "../Register/register.css";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';





const Register = () => {
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate()



    useEffect(() => {
        setShowAlert(true);
    }, []);

    const handleClose = () => {
        navigate('/');
    };


    const [user, SetUser] = useState({
        FirstName: "",
        LastName: "",
        Email: "",
        EventPresence: "",
        Location: "",
        Function: ""
    })

    const handleChange = e => {
        const { name, value } = e.target;
        SetUser({
            ...user,
            [name]: value
        })
    }

    // API for handing Data

    const handleRegister = (event) => {
        event.preventDefault();

        // axios.post(process.env.REACT_APP_ADMIN + "/saveregister", user)
        //     .then(response => {
        //         console.log('Success:', response.data);

        //         Swal.fire({
        //             html: '<span style="color: #004c00">Thank you for registering.Please check your inbox & spam folder for your event QR code,which is required for entry.</span>',
        //             icon: 'success',
        //             confirmButtonText: 'Close',
        //         }).then(() => {
        //             window.location.reload();
        //         });

        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });





        axios.post(process.env.REACT_APP_ADMIN + "/saveregister", user)
            .then(response => {
                if (response.data.message === "Already registered!") {
                    Swal.fire({
                        html: '<span style="color: #ff0000">User already registered!</span>',
                        icon: 'error',
                        confirmButtonText: 'Close'
                    });
                } else {
                    console.log('Success:', response.data);
                    Swal.fire({
                        html: '<span style="color: #004c00">Thank you for registering. Please check your inbox & spam folder for your event QR code, which is required for entry.</span>',
                        icon: 'success',
                        confirmButtonText: 'Close'
                    }).then(() => {
                        window.location.reload();
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });

    };



    return (
        <div className="header">
            {/* alert to close registrations */}
            {/* {showAlert && Swal.fire({
                type: 'error',
                title: 'Registration Closed',
                text: 'Unfortunately, registration is currently closed. Please check back later.',
                onClose: handleClose()
            })} */}

            <div className="nav p-3 ">
                <Row className="navrow">
                    <Col lg={6} md={6} sm={12}><Button variant="secondary" onClick={() => navigate("/")} className="px-4 btn-1">Home</Button></Col>
                    {/* <Col lg={6} md={6} sm={12}><Button variant="secondary" onClick={() => navigate("/feedback")} className="">Feedback</Button></Col> */}
                </Row>
            </div>
            <div className="containers">
                <Form onSubmit={(event) => {
                    // event.preventDefault();
                    handleRegister(event)
                }}>
                    <div className="containers-content">
                        <p className="label1">Please fill in the required information below
                        </p>
                        <p className="text-muted d-flex">Fields with an (*) are mandatory
                        </p>
                        <Row className="rg-disp">
                            <Col>
                                <label>First Name *</label>
                                <input className="form-input" type="name" name="FirstName" value={user.FirstName} onChange={handleChange} required />

                            </Col>
                            <Col>
                                <label>Last Name *</label>
                                <input className="form-input" type="name" name="LastName" value={user.LastName} onChange={handleChange} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Official Email *</label><br />
                                <input className="email" type="email" value={user.Email} name="Email" onChange={handleChange} required />
                            </Col>
                        </Row>
                        <Row onChange={handleChange}>
                            <Col lg={6} md={6}><label>Will you attend the event *</label></Col>
                            <Col>
                                <input type="radio" className="form-input" id="Yes" name="EventPresence" defaultChecked={user.EventPresence === "Yes"} value="Yes" required></input>
                                <label htmlFor="Yes">Yes</label>
                            </Col>
                            <Col>
                                <input type="radio" className="form-input" id="No" name="EventPresence" defaultChecked={user.EventPresence === "No"} value="No" required></input>
                                <label htmlFor="No">No</label>
                            </Col>
                        </Row>


                        <label htmlFor="Location">Location *</label>
                        <select name="Location" className="form-select" id="Location" value={user.Location} onChange={handleChange} required>
                            <option value="" hidden>Select Location</option>
                            <option value="Delhi/NCR">Delhi/NCR</option>
                            <option value="Chennai">Chennai</option>
                            <option value="Bengaluru">Bengaluru</option>
                            <option value="Pune">Pune</option>
                            <option value="Overseas">Overseas</option>
                        </select>



                        {/* <br /> */}
                        {/* <Row onChange={handleChange}>
                            <Col lg={2} md={12} sm={12}> <label>Company</label></Col>
                            <Col lg={4} md={6} sm={12}>
                                <input type="radio" id="sopra" name="Company" value="sopra Steria" defaultChecked={user.Company === "sopra Steria"} className="form-input" required></input>
                                <label htmlFor="sopra">Sopra Steria</label>
                            </Col>
                            <Col lg={6} md={8} sm={12}>
                                <input type="radio" id="software" name="Company" value="software Sopra Banking" defaultChecked={user.Company === "software Sopra Banking"} className="form-input" required></input>
                                <label htmlFor="software">Sopra Banking Software</label>
                            </Col>
                        </Row> */}

                        <label htmlFor="Function">Function *</label>
                        <select name="Function" className="form-select" id="Function" value={user.Function} onChange={handleChange} required>
                            <option value="" hidden>Select Function</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="Continental Europe">Continental Europe</option>
                            <option value="Aeroline">Aeroline</option>
                            <option value="Axway">Axway</option>
                            <option value="BPS">BPS</option>
                            <option value="PMG">PMG</option>
                            <option value="shared Services">Shared Services</option>
                            <option value="DI">DI</option>
                            <option value="TEC">TEC</option>
                            <option value="Digital Offerings">Digital Offerings</option>
                            <option value="HR">HR</option>
                            <option value="Finance">Finance</option>
                            <option value="France V&R">France V&R</option>
                            <option value="Legal">Legal</option>
                            <option value="MarCom">MarCom</option>
                            <option value="Admin & Facilities">Admin & Facilities</option>
                            <option value="DSI">DSI</option>
                            <option value="Senior Management">Senior Management</option>
                            <option value="Sopra Banking Software">Sopra Banking Software</option>
                            <option value="Group">Group</option>
                        </select>






                        <div className="btns"  >
                            <Button type="submit" variant="danger">Register</Button>
                        </div>

                    </div>
                </Form>
            </div>
        </div>

    );
};

export default Register;