import React, { useState } from "react";
import { Button, Form } from 'react-bootstrap';
import "../feedback/feedback.css";
import "../feedback/feedbacks.css";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';



const Feedback = () => {
    const navigate = useNavigate()

    const [feed, SetFeed] = useState({
        Name: "",
        Email: "",
        Theme_of_all_booths: "",
        Content_of_all_booths: "",
        comment: "",
        video_animations: "",
        Content_structure: "",
        registration: "",
        Communication: "",
        event_and_the_elements: "",
        planning_DU_function: "",
        engagement: "",
        feedback: ""
    })

    const handleEvent = e => {
        const { name, value } = e.target;
        SetFeed({
            ...feed,
            [name]: value
        })
    }

    const handlefeedback = (event) => {
        event.preventDefault();
        axios.post(process.env.REACT_APP_ADMIN + "/savefeedback", feed)
            .then(response => {
                console.log('Success:', response.data);

                Swal.fire({
                    html: '<span style="color: #004c00; text-align: left;">Thanks for giving your valuable feedback.</span>',
                    icon: 'success',
                    confirmButtonText: 'Close',
                }).then(() => {
                    window.location.reload();
                });

            })
            .catch(error => {
                console.error('Error:', error);
            });
    };





    return (
        <div className="header">

            <div className="nav p-3 ps-4">
                <div className="navrow">
                    <Button variant="secondary " onClick={() => navigate("/")} className="btn-1 px-4">Home</Button>
                </div>
            </div>
            <div className="head-content">
                <h4>Complete the survey</h4>
            </div>
            <Form onSubmit={handlefeedback} onChange={handleEvent}>
                <div className="content">
                    <div className="content-name-fd">


                        <div className="onen">
                            <label className="mb-1">Full Name *</label>
                            <input className="form-content" type="name" value={feed.Name} name="Name" required />
                        </div>

                        <div className="onen">
                            <label className="mb-1">Email *</label>
                            <input className="form-content" type="email" value={feed.Email} name="Email" required />
                        </div>
                    </div>


                    <p className="mt-2">Your opinion matters to us! tell us how you felt at the kick off 2023 by taking up the survey below</p>
                    <p className="mb-0">1. What is your overall assessment of the complete event?</p>

                    <div class="rate">
                        <input type="radio" id="star5" name="ratings" value="5" />
                        <label className="m-2" for="star5" title="text">5 stars</label>
                        <input type="radio" id="star4" name="ratings" value="4" />
                        <label className="m-2" for="star4" title="text">4 stars</label>
                        <input type="radio" id="star3" name="ratings" value="3" />
                        <label className="m-2" for="star3" title="text">3 stars</label>
                        <input type="radio" id="star2" name="ratings" value="2" />
                        <label className="m-2" for="star2" title="text">2 stars</label>
                        <input type="radio" id="star1" name="ratings" value="1" />
                        <label className="m-2" for="star1" title="text">1 star</label>
                    </div>

                    <br></br>
                    <br></br>
                    <br></br>


                    <p>2. What do you think about the booths set-up?</p>
                    <span>
                        <p className="inside">Theme of all booths</p>
                        <div className="one">
                            <input className="input" type="radio" name="Theme_of_all_booths" id="Not at all Satisfied" defaultChecked={feed.EventPresence === "Not at all Satisfied"} value="Not at all Satisfied"  ></input>
                            <label htmlFor="Not at all Satisfied">Not at all Satisfied</label></div>
                        <div className="one">
                            <input className="input" type="radio" name="Theme_of_all_booths" id="Not very Satisfied" defaultChecked={feed.EventPresence === "Not very Satisfied"} value="Not very Satisfied"  ></input>
                            <label htmlFor="Not very Satisfied">Not very Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="Theme_of_all_booths" id="Moderately Satisfied" defaultChecked={feed.EventPresence === "Moderately Satisfied"} value="Moderately Satisfied"  ></input>
                            <label htmlFor="Moderately Satisfied">Moderately Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="Theme_of_all_booths" id="Quite Satisfied" defaultChecked={feed.EventPresence === "Quite Satisfied"} value="Quite Satisfied"  ></input>
                            <label htmlFor="Quite Satisfied">Quite Satisfied</label></div>
                        <div className="one"> <input className="input" type="radio" name="Theme_of_all_booths" id="Very Satisfied" defaultChecked={feed.EventPresence === "Very Satisfied"} value="Very Satisfied"  ></input>
                            <label htmlFor="Very Satisfied">Very Satisfied</label></div>
                    </span>



                    <span>
                        <p className="inside">Content of all booths</p>
                        <div className="one">
                            <input className="input" type="radio" name="Content_of_all_booths" id="Not at all Satisfied" defaultChecked={feed.EventPresence === "Not at all Satisfied"} value="Not at all Satisfied"  ></input>
                            <label htmlFor="Not at all Satisfied">Not at all Satisfied</label></div>
                        <div className="one"> <input className="input" type="radio" name="Content_of_all_booths" id="Not very Satisfied" defaultChecked={feed.EventPresence === "Not very Satisfied"} value="Not very Satisfied"  ></input>
                            <label htmlFor="Not very Satisfied">Not very Satisfied</label></div>
                        <div className="one"> <input className="input" type="radio" name="Content_of_all_booths" id="Moderately Satisfied" defaultChecked={feed.EventPresence === "Moderately Satisfied"} value="Moderately Satisfied"  ></input>
                            <label htmlFor="Moderately Satisfied">Moderately Satisfied</label></div>
                        <div className="one"> <input className="input" type="radio" name="Content_of_all_booths" id="Quite Satisfied" defaultChecked={feed.EventPresence === "Quite Satisfied"} value="Quite Satisfied"  ></input>
                            <label htmlFor="Quite Satisfied">Quite Satisfied</label></div>
                        <div className="one"> <input className="input" type="radio" name="Content_of_all_booths" id="Very Satisfied" defaultChecked={feed.EventPresence === "Very Satisfied"} value="Very Satisfied"  ></input>
                            <label htmlFor="Very Satisfied">Very Satisfied</label></div>
                    </span>
                    <p>3. Comments on preliminary event-</p>
                    <textarea className="input" rows="5" cols="30" type="text" value={feed.comment} name="comment"  ></textarea>


                    <span>
                        <p>4. Did you enjoy the main event?</p>
                        <span>
                            <p className="inside">video & animations</p>
                            <div className="one"> <input className="input" type="radio" name="video_animations" id="Not at all Satisfied" defaultChecked={feed.EventPresence === "Not at all Satisfied"} value="Not at all Satisfied"  ></input>
                                <label htmlFor="Not at all Satisfied">Not at all Satisfied</label></div>
                            <div className="one"><input className="input" type="radio" name="video_animations" id="Not very Satisfied" defaultChecked={feed.EventPresence === "Not very Satisfied"} value="Not very Satisfied"  ></input>
                                <label htmlFor="Not very Satisfied">Not very Satisfied</label></div>
                            <div className="one"><input className="input" type="radio" name="video_animations" id="Moderately Satisfied" defaultChecked={feed.EventPresence === "Moderately Satisfied"} value="Moderately Satisfied"  ></input>
                                <label htmlFor="Moderately Satisfied">Moderately Satisfied</label></div>
                            <div className="one"> <input className="input" type="radio" name="video_animations" id="Quite Satisfied" defaultChecked={feed.EventPresence === "Quite Satisfied"} value="Quite Satisfied"  ></input>
                                <label htmlFor="Quite Satisfied">Quite Satisfied</label></div>
                            <div className="one"><input className="input" type="radio" name="video_animations" id="Very Satisfied" defaultChecked={feed.EventPresence === "Very Satisfied"} value="Very Satisfied"  ></input>
                                <label htmlFor="Very Satisfied">Very Satisfied</label></div>
                        </span>
                        <span>
                            <p className="inside">Content & structure</p>
                            <div className="one"><input className="input" type="radio" name="Content_structure" id="Not at all Satisfied" defaultChecked={feed.EventPresence === "Not at all Satisfied"} value="Not at all Satisfied"  ></input>
                                <label htmlFor="Not at all Satisfied">Not at all Satisfied</label></div>
                            <div className="one"><input className="input" type="radio" name="Content_structure" id="Not very Satisfied" defaultChecked={feed.EventPresence === "Not very Satisfied"} value="Not very Satisfied"  ></input>
                                <label htmlFor="Not very Satisfied">Not very Satisfied</label></div>
                            <div className="one"><input className="input" type="radio" name="Content_structure" id="Moderately Satisfied" defaultChecked={feed.EventPresence === "Moderately Satisfied"} value="Moderately Satisfied"  ></input>
                                <label htmlFor="Moderately Satisfied">Moderately Satisfied</label></div>
                            <div className="one"><input className="input" type="radio" name="Content_structure" id="Quite Satisfied" defaultChecked={feed.EventPresence === "Quite Satisfied"} value="Quite Satisfied"  ></input>
                                <label htmlFor="Quite Satisfied">Quite Satisfied</label></div>
                            <div className="one"><input className="input" type="radio" name="Content_structure" id="Very Satisfied" defaultChecked={feed.EventPresence === "Very Satisfied"} value="Very Satisfied"  ></input>
                                <label htmlFor="Very Satisfied">Very Satisfied</label></div>
                        </span>
                    </span>
                    <p>5. How would you rate the overall event?</p>
                    <span>
                        <p className="inside">registration</p>
                        <div className="one"><input className="input" type="radio" name="registration" id="Not at all Satisfied" defaultChecked={feed.EventPresence === "Not at all Satisfied"} value="Not at all Satisfied"  ></input>
                            <label htmlFor="Not at all Satisfied">Not at all Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="registration" id="Not very Satisfied" defaultChecked={feed.EventPresence === "Not very Satisfied"} value="Not very Satisfied"  ></input>
                            <label htmlFor="Not very Satisfied">Not very Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="registration" id="Moderately Satisfied" defaultChecked={feed.EventPresence === "Moderately Satisfied"} value="Moderately Satisfied"  ></input>
                            <label htmlFor="Moderately Satisfied">Moderately Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="registration" id="Quite Satisfied" defaultChecked={feed.EventPresence === "Quite Satisfied"} value="Quite Satisfied"  ></input>
                            <label htmlFor="Quite Satisfied">Quite Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="registration" id="Very Satisfied" defaultChecked={feed.EventPresence === "Very Satisfied"} value="Very Satisfied"  ></input>
                            <label htmlFor="Very Satisfied">Very Satisfied</label></div>
                    </span>
                    <span>
                        <p className="inside">Communication</p>

                        <div className="one"><input className="input" type="radio" name="Communication" id="Not at all Satisfied" defaultChecked={feed.EventPresence === "Not at all Satisfied"} value="Not at all Satisfied"  ></input>
                            <label htmlFor="Not at all Satisfied">Not at all Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="Communication" id="Not very Satisfied" defaultChecked={feed.EventPresence === "Not very Satisfied"} value="Not very Satisfied"  ></input>
                            <label htmlFor="Not very Satisfied">Not very Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="Communication" id="Moderately Satisfied" defaultChecked={feed.EventPresence === "Moderately Satisfied"} value="Moderately Satisfied"  ></input>
                            <label htmlFor="Moderately Satisfied">Moderately Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="Communication" id="Quite Satisfied" defaultChecked={feed.EventPresence === "Quite Satisfied"} value="Quite Satisfied"  ></input>
                            <label htmlFor="Quite Satisfied">Quite Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="Communication" id="Very Satisfied" defaultChecked={feed.EventPresence === "Very Satisfied"} value="Very Satisfied"  ></input>
                            <label htmlFor="Very Satisfied">Very Satisfied</label></div>
                    </span>
                    <p>6. How would you rate the theme of the event and the elements (poetry,painting, branding) that helped put the entire evening together?</p>
                    <span>

                        <div className="one"> <input className="input" type="radio" name="event_and_the_elements" id="Not at all Satisfied" defaultChecked={feed.EventPresence === "Not at all Satisfied"} value="Not at all Satisfied"  ></input>
                            <label htmlFor="Not at all Satisfied">Not at all Satisfied</label></div>
                        <div className="one"> <input className="input" type="radio" name="event_and_the_elements" id="Not very Satisfied" defaultChecked={feed.EventPresence === "Not very Satisfied"} value="Not very Satisfied"  ></input>
                            <label htmlFor="Not very Satisfied">Not very Satisfied</label></div>
                        <div className="one"> <input className="input" type="radio" name="event_and_the_elements" id="Moderately Satisfied" defaultChecked={feed.EventPresence === "Moderately Satisfied"} value="Moderately Satisfied"  ></input>
                            <label htmlFor="Moderately Satisfied">Moderately Satisfied</label></div>
                        <div className="one"> <input className="input" type="radio" name="event_and_the_elements" id="Quite Satisfied" defaultChecked={feed.EventPresence === "Quite Satisfied"} value="Quite Satisfied"  ></input>
                            <label htmlFor="Quite Satisfied">Quite Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="event_and_the_elements" id="Very Satisfied" defaultChecked={feed.EventPresence === "Very Satisfied"} value="Very Satisfied"  ></input>
                            <label htmlFor="Very Satisfied">Very Satisfied</label></div>
                    </span>

                    <p>7. Were the different sessions conducted for cascading the groups message helpful for you in planning the roadmap for you planning DU/function?</p>
                    <span>
                        <div className="one"><input className="input" type="radio" name="planning_DU_function" id="Not at all Satisfied" defaultChecked={feed.EventPresence === "Not at all Satisfied"} value="Not at all Satisfied"  ></input>
                            <label htmlFor="Not at all Satisfied">Not at all Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="planning_DU_function" id="Not very Satisfied" defaultChecked={feed.EventPresence === "Not very Satisfied"} value="Not very Satisfied"  ></input>
                            <label htmlFor="Not very Satisfied">Not very Satisfied</label></div>
                        <div className="one"> <input className="input" type="radio" name="planning_DU_function" id="Moderately Satisfied" defaultChecked={feed.EventPresence === "Moderately Satisfied"} value="Moderately Satisfied"  ></input>
                            <label htmlFor="Moderately Satisfied">Moderately Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="planning_DU_function" id="Quite Satisfied" defaultChecked={feed.EventPresence === "Quite Satisfied"} value="Quite Satisfied"  ></input>
                            <label htmlFor="Quite Satisfied">Quite Satisfied</label></div>
                        <div className="one"> <input className="input" type="radio" name="planning_DU_function" id="Very Satisfied" defaultChecked={feed.EventPresence === "Very Satisfied"} value="Very Satisfied"  ></input>
                            <label htmlFor="Very Satisfied">Very Satisfied</label></div>

                        {/* <input className="input" type="radio" name="planning_DU_function" id="Not at all Satisfied" defaultChecked={feed.EventPresence === "Not at all Satisfied"} value="Not at all Satisfied"  ></input>
                        <label htmlFor="Not at all Satisfied">Not at all Satisfied</label>
                        <input className="input" type="radio" name="planning_DU_function" id="Not very Satisfied" defaultChecked={feed.EventPresence === "Not very Satisfied"} value="Not very Satisfied"  ></input>
                        <label htmlFor="Not very Satisfied">Not very Satisfied</label>
                        <input className="input" type="radio" name="planningDU_function" id="Moderately Satisfied" defaultChecked={feed.EventPresence === "Moderately Satisfied"} value="Moderately Satisfied"  ></input>
                        <label htmlFor="Moderately Satisfied">Moderately Satisfied</label>
                        <input className="input" type="radio" name="planningDU_function" id="Quite Satisfied" defaultChecked={feed.EventPresence === "Quite Satisfied"} value="Quite Satisfied"  ></input>
                        <label htmlFor="Quite Satisfied">Quite Satisfied</label>
                        <input className="input" type="radio" name="planningDU_function" id="Very Satisfied" defaultChecked={feed.EventPresence === "Very Satisfied"} value="Very Satisfied"  ></input>
                        <label htmlFor="Very Satisfied">Very Satisfied</label> */}
                    </span>

                    <p>8. How would you rate the employee engagement activities? </p>
                    <span>
                        <div className="one"><input className="input" type="radio" name="engagement" id="Not at all Satisfied" defaultChecked={feed.EventPresence === "Not at all Satisfied"} value="Not at all Satisfied"  ></input>
                            <label htmlFor="Not at all Satisfied">Not at all Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="engagement" id="Not very Satisfied" defaultChecked={feed.EventPresence === "Not very Satisfied"} value="Not very Satisfied"  ></input>
                            <label htmlFor="Not very Satisfied">Not very Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="engagement" id="Moderately Satisfied" defaultChecked={feed.EventPresence === "Moderately Satisfied"} value="Moderately Satisfied"  ></input>
                            <label htmlFor="Moderately Satisfied">Moderately Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="engagement" id="Quite Satisfied" defaultChecked={feed.EventPresence === "Quite Satisfied"} value="Quite Satisfied"  ></input>
                            <label htmlFor="Quite Satisfied">Quite Satisfied</label></div>
                        <div className="one"><input className="input" type="radio" name="engagement" id="Very Satisfied" defaultChecked={feed.EventPresence === "Very Satisfied"} value="Very Satisfied"  ></input>
                            <label htmlFor="Very Satisfied">Very Satisfied</label></div>
                    </span>
                    <span>
                        <p>9. please share your overall feedback/ additional comments here-</p>
                        <textarea className="input" rows="3" cols="35" type="text" value={feed.feedback} name="feedback"  ></textarea>
                    </span>

                </div>
                <div className="btns">
                    <Button variant="danger" type="submit">Send My Information</Button>
                </div>
            </Form>
        </div>





    );
};

export default Feedback;