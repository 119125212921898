import React from "react";
import { Row, Col, Button } from 'react-bootstrap';
import "../Homepage/homepage.css";
import { BrowserView, MobileView } from 'react-device-detect';
import { useNavigate } from 'react-router-dom'


const Homepage = () => {
    const navigate = useNavigate()
    return (
        <div className="header">
          
            <div className="navbar p-4 ps-4">
                <Row className="navrow">

                    {/* <Col lg={6} md={6} sm={12}><Button variant="secondary" onClick={() => navigate("/register")} className="px-3  me-3 btn-1">Register</Button></Col> */}
                    <Col lg={6} md={6} sm={12}><Button variant="secondary" onClick={() => navigate("/feedback")} className="">Feedback</Button></Col>

                </Row>
            </div>

            <div className="images">
                <BrowserView>
                    <img src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Sopra%20steria%2FSopra%20Landing%20page.jpg?alt=media&" width="100%"></img>
                </BrowserView>
                <MobileView>
                    <img src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Sopra%20steria%2Fsopra%20mobile.png?alt=media" width="100%"></img>
                </MobileView>




            </div>
        </div>
    )
};

export default Homepage;